import { makeStyles } from '@material-ui/core'
import theme from '../../theme'

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    borderRadius: 0,
    backgroundColor: theme.palette.secondary.light,
    textTransform: 'none',
    '&:after': {
      content: '" "',
      position: 'absolute',
      bottom: '-100%',
      left: 0,
      width: '100%',
      height: '100%',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${require('../../assets/img/btn-endpoint.png')})`,
    },
    '& .MuiButton-label': {
      padding: '11px 30px',
      [theme.breakpoints.down('xs')]: {
        padding: '6px 15px',
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.active': {
    },
  },
})

export default useStyles
