export const Boomerang = ({
  width = 990,
  height = 735,
  ...other
}) => (
  <svg {...other} width={width} height={height} viewBox='0 0 990 735' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M502.116 446.619C360.277 524.152 142.444 508.802 56.8986 503.626C153.689 557.788 392.071 664.196 581.652 564.536C762.541 469.446 826.448 154.32 831.656 -0.000241966C784.67 124.694 663.217 358.557 502.116 446.619Z' fill='#FFC700' />
  </svg>
)

export default Boomerang