import { Typography, Container } from '@material-ui/core'
import Button from '../Button'
import cx from 'clsx'
import useStyles from './styles'
import { useCallback, useState, useEffect } from 'react'
import Boomerang from '../Boomerang'


const TEXTS = [
  'Steuerplanung zur Optimierung der Steuerbelastung',
  'Antragstellung in steuerlichen Angelegenheiten',
  'Erstellung von Jahresabschlüssen',
  'Erstellung von Steuererklärungen für Privatpersonen',
  'Wie wir arbeiten',
  'Steuerplanung zur Optimierung der Steuerbelastung',
  'Antragstellung in steuerlichen Angelegenheiten',
  'Erstellung von Jahresabschlüssen',
  'Erstellung von Steuererklärungen für Privatpersonen',
  'Wie wir arbeiten',
  // 'Steuerplanung zur Optimierung der Steuerbelastung',
  // 'Antragstellung in steuerlichen Angelegenheiten',
  // 'Erstellung von Jahresabschlüssen',
  // 'Erstellung von Steuererklärungen für Privatpersonen',
  // 'Wie wir arbeiten',
  // 'Steuerplanung zur Optimierung der Steuerbelastung',
  // 'Antragstellung in steuerlichen Angelegenheiten',
  // 'Erstellung von Jahresabschlüssen',
  // 'Erstellung von Steuererklärungen für Privatpersonen',
  // 'Wie wir arbeiten',
  // 'Steuerplanung zur Optimierung der Steuerbelastung',
  // 'Antragstellung in steuerlichen Angelegenheiten',
  // 'Erstellung von Jahresabschlüssen',
  // 'Erstellung von Steuererklärungen für Privatpersonen',
  // 'Wie wir arbeiten',
  // 'Steuerplanung zur Optimierung der Steuerbelastung',
  // 'Antragstellung in steuerlichen Angelegenheiten',
  // 'Erstellung von Jahresabschlüssen',
  // 'Erstellung von Steuererklärungen für Privatpersonen',
]

console.log('TEXTS', TEXTS.length)
let i

const MainPage = ({ content }) => {
  const cls = useStyles(0)
  const [playing, setPlaying] = useState(false)
  const [destroying, setDestroying] = useState(false)
  const handlePlayClick = useCallback(e => {
    e.preventDefault()
    setPlaying(p => !p)
  }, [])
  useEffect(() => {
    clearTimeout(i)
    document.body.classList.toggle('playing', playing)
    if (playing) {
      i = setTimeout(() => {
        setDestroying(true)
      }, 1000 * TEXTS.length * 2)
    } else {
      setDestroying(false)
    }
  }, [playing])

  return (
    <>
      <div className={cx(
        cls.root,
        playing && cls.play,
        destroying && cls.destroy,
      )}
      >
        <div className={cx(cls.rootBg)} />
        <div className={cls.mainBg} />

        <div className={cls.falling}>
          {TEXTS.map((text, index) => (
            <Typography
              key={index}
              variant={'h3'}
              className={cx(cls.ftext, cls[`ftext${index}`])}
            >
              {text}
            </Typography>
          ))}
          <Boomerang className={cls.logo} />
        </div>


        <div className={cls.pros}>
          <Container>
            <Typography
              variant='h2'
              style={{ animationDelay: `1s` }}
            >
              Sie erhalten einen freien Kopf bezüglich Steuerfragen!
            </Typography>
            <Typography
              variant='h2'
              style={{ animationDelay: `2s` }}
            >
              Planung von Liquidati nen, Neu — und Umgründungen
            </Typography>
            <Typography
              variant='h2'
              style={{ animationDelay: `3s` }}
            >
              Erbschafts — und Unternehmensnachfolgefragen
            </Typography>
          </Container>
        </div>


        <Container>
          <div className={cls.text}>
            <Typography variant='h1'>{content.home_title}</Typography>
            <Typography variant='body1'>{content.home_description}</Typography>
          </div>
          <div className={cls.btn}>
            <Button onClick={handlePlayClick} href={'#'}>
              <Typography variant='body1'>Wie wir arbeiten</Typography>
            </Button>
          </div>
        </Container>


      </div>

    </>
  )
}

export default MainPage
