import clsx from 'clsx'
import { Button as MuiButton } from '@material-ui/core'
import useStyles from './styles'

const Button = ({ className = null, ...other }) => {
  const cls = useStyles(0)

  return (
    <MuiButton
      {...other}
      className={clsx(cls.root, className)}
      disableRipple={true}
    />
  )
}

export default Button
