import MetaTags from '../components/MetaTags'
import MainPage from '../components/MainPage'

const IndexPage = ({ meta, content }) => {
  return (
    <>
      <MetaTags meta={meta} />
      <MainPage content={content} />
    </>
  )
}

export const getStaticProps = async ({ locale }) => {
  const content = await import('@/content/pages/home.json').then(x => x.default) as any

  return {
    props: {
      meta: content?.meta?.[locale] || {},
      content: content[locale] || {},
    },
  }
}

export default IndexPage


