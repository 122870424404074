import { makeStyles } from '@material-ui/core'
import theme from '../../theme'

export const useStyles = makeStyles(({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  text: {
    transition: 'all 1s ease-in',

    width: '50%',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: 95,
      width: '100%',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 50,
    },
  },
  btn: {
    // position: 'absolute',
    textAlign: 'center',
    marginBottom: 60,
    marginTop: -120,
  },

  // Мужик
  mainBg: {
    transition: 'all .7s ease-out',
    backgroundImage: `url(${require('../../assets/img/team_item.png')})`,
    width: '100%',
    height: '85%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',
    bottom: 0,
    right: 0,
    backgroundPosition: '100% 100%',
    opacity: 1,
    [theme.breakpoints.down('sm')]: {
      height: '62%',
    },
    [theme.breakpoints.down('xs')]: {
      height: '47%',
    },
  },

  // Серый фон
  rootBg: {
    transition: 'all 1s ease-out',
    zIndex: -1,
    opacity: 1,
    top: -130,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    background: `radial-gradient(100% 100% at 96.56% 100%, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark} 100%)`,
  },

  // Контейнер летающего текста
  falling: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: '100%',
    position: 'absolute',
    transform: `translateY(100%)`,
    transition: 'all 1s ease-out',
  },

  // Летающий текст
  ftext: {
    position: 'absolute',
  },

  play: {
    '& $rootBg': {
      opacity: 0,
      transform: `translateY(-200%)`,
    },
    '& $mainBg': {
      opacity: 0,
      height: '50%',
      transform: `translateY(-1000px)`,
    },
    '& $text': {
      opacity: 0,
      transform: `translateY(-300%)`,
    },
    '& $falling': {
      transform: `translateY(0)`,
    },
  },


  '@keyframes apros': {
    '0%': {
      opacity: 0,
      transform: `translateY(100px)`,
    },
    '100%': {
      opacity: 1,
      transform: `translateY(0)`,
    },
  },


  // Финальные фраза
  pros: {
    bottom: 150,
    left: 0,
    right: 0,
    position: 'absolute',
    paddingTop: '3rem',
    textAlign: 'justify',
    '& h2': {
      opacity: 0,
    },
    '$destroy &': {
      '& h2': {
        animation: `$apros 1.5s ease-in forwards`,
      },
    },
  },

  destroy: {},
  highlight: {},

  ...getFallingTexts(),
}))


function getFallingTexts() {
  const total = 10
  // if (typeof window === 'undefined') { return {} }
  const h = typeof window === 'undefined' ? 960 : window.innerHeight
  const w = typeof window === 'undefined' ? 1200 : window.innerWidth
  const exitAnimationTiming = 3

  const styles = (new Array(total)).fill(1).reduce((acc, _, index) => {
    const isOdd = (index % 2)
    const isCenter = !(index % 3)
    const isStatic = total - index < 5

    const startX = isOdd ? -200 : w + 200
    const endX = w / 2 + (
      isCenter ? (Math.random() * 100) :
        isOdd
          ? (-1 * (w / 4 + Math.round(Math.random() * w / 3)))
          : Math.round(Math.random() * w / 3))
    const startY = isOdd ? h + 200 : -200
    const endY = isStatic
      ? h / 2 + (Math.round(Math.random() * h / 3) * (isOdd ? 1 : -1))
      : isOdd ? -3 * h : h * 3

    const rotate = isOdd ? Math.round(Math.random() * 125) : Math.round(-125 * Math.random())
    // isOdd ? h + Math.round(Math.random() * 200) : Math.round(Math.random() * 200)


    if (isStatic) {
      acc[`@keyframes aftextdestroy${index}`] = {
        '0%': {
          opacity: 1,
          transform: `translate(${endX}px, ${endY}px)  rotate(${rotate}deg)`,
        },
        '30%': {
          opacity: .5,
          filter: `blur(${Math.round(1 + Math.random() * 10)}px)`,
          transform: `translate(${endX / 2}px, ${endY / 2}px)  rotate(125deg)`,
        },
        '100%': {
          opacity: 0,
          transform: `translate(${startX}px,${startY}px)  rotate(0deg)`,
        },
      }
    }

    acc[`@keyframes aftext${index}`] = {
      '0%': {
        opacity: .5,
        filter: 'blur(0px)',
        transform: `translate(${startX}px,${startY}px)  rotate(0deg)`,
      },
      '51%': {
        opacity: .75,
        filter: `blur(${Math.round(1 + Math.random() * 10)}px)`,
        transform: `translate(${endX / 2}px, ${endY / 2}px)  rotate(125deg)`,
      },
      '100%': {
        opacity: 1,
        // filter: `blur(0px)`,
        transform: `translate(${endX}px, ${endY}px)  rotate(${rotate}deg)`,
      },
    }

    acc[`ftext${index}`] = {
      opacity: 0,
      transform: `translate(${startX}px,${startY}px)  rotate(0deg)`,
      maxWidth: 200 + Math.round(Math.random() * 300),
      '$play &': {
        animation: `$aftext${index} ease-in-out ${isStatic ? total + index : total * 2}s forwards`,
        animationDelay: `${index * .3}s`,
        // 'animation-iteration-count': 1,
        'transform-origin': '50% 50%',
      },
      '$destroy &': !isStatic ? {} : {
        animation: `$aftextdestroy${index} ease-in-out ${exitAnimationTiming}s forwards`,
      },
    }
    return acc
  }, {})


  styles[`@keyframes alogo`] = {
    '0%': {
      top: '50%',
      left: '50%',
      opacity: 0,
      transform: 'scale(0,0)',
    },
    '10%': {
      opacity: 1,
      transform: 'scale(.25,.25) rotate(15deg)',
    },
    '20%': {
      top: '50%',
      left: '50%',
      opacity: 1,
      transform: 'scale(.5,.5) rotate(45deg)',
    },
    '40%': {
      top: '50%',
      left: '50%',
      opacity: 1,
      transform: 'scale(.75,.75) rotate(180deg)',
    },
    '75%': {
      top: 100,
      left: 100,
      opacity: 1,
      transform: 'scale(.6,.6) rotate(200deg)',
    },
    '100%': {
      top: -1000,
      left: 2000,
      transform: 'scale(.5,.5) rotate(0deg)',
      opacity: 1,
    },
  }

  styles['logo'] = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: '-25%',
    marginTop: '-25%',
    opacity: 0,

    [theme.breakpoints.down('sm')]: {
      width: 200,
    },

    '$play &': {
      animation: `$alogo ease-in-out ${exitAnimationTiming * .5}s forwards`,
      animationDelay: `${total * 1.9}s`,
    },
  }

  return styles
}

export default useStyles
